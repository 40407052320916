import request from "@utils/request";

//我的钱包
export const myWallet = (params)=>{
    return request({
        url:'index/center/wallet',
        method:'post',
        params
    })
}
//购买记录
export const getOrderList = (params) =>{
    return request({
        url:'index/shop/getOrderList',
        method:'post',
        params
    })
}
//消费记录
export const getExpensesRecordList = (params)=>{
    return request({
        url:'index/shop/getExpensesRecordList',
        method:'post',
        params
    })
}
//提现申请数据
export const getWithdraw = (params) =>{
    return request({
        url:'index/center/withdraw',
        method:'post',
        params,
    })
}
//申请提现
export const sendWithdraw = (params) =>{
    return request({
        url:'index/center/sendWithdraw',
        method:'post',
        params
    })
}
